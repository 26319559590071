import React, {Component} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '15'
    }
};

class AccountsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            unassignedUsers: [],
            totalPages: -1,
            pageSize: 5,
            pageIndex: 0,
            loading: true,
            numberOfAccountsToAdd: 1,
        };

        this.loadAccounts = this.loadAccounts.bind(this);
        this.loadUnassignedUsers = this.loadUnassignedUsers.bind(this);
        this.addAccountsAndUpdate = this.addAccountsAndUpdate.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.unassignLicense = this.unassignLicense.bind(this);
        this.close = this.close.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.customFilter = this.customFilter.bind(this);
    }

    async loadAccounts() {
        this.setState({loading: true});
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.client.get(`/v3/customers/${id}/accounts`, {
                params: {
                    page: this.state.pageIndex,
                    size: this.state.pageSize,
                }
            });
            this.setState({
                accounts: response.data.content,
                totalPages: response.data.totalPages,
                loading: false,
            })
        } else {
            console.log("CustomerID not defined, cannot load accounts.")
        }
    }

    async loadUnassignedUsers() {
        this.setState({loading: true});
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.client.get(`/customers/${id}/unassignedUsers`);
            this.setState({
                unassignedUsers: response.data,
                loading: false,
            })
        }
    }

    addAccounts() {
        for (var i = 0; i < this.state.numberOfAccountsToAdd; i++)
            this.props.client.post(`/customers/${this.props.customerId}/accounts`);
    }

    async addAccountsAndUpdate() {
        await this.addAccounts();
        this.loadAccounts()
    }

    async deleteAccount(id) {
        await this.props.client.delete(`/accounts/${id}`);
        this.loadAccounts()
    }

    firstPage() {
        this.setState({pageIndex: 0}, () => {
            this.loadAccounts()
        })
    }

    lastPage() {
        this.setState({pageIndex: this.state.totalPages - 1}, () => {
            this.loadAccounts()
        })
    }

    prevPage() {
        const nextPage = this.state.pageIndex - 1;
        if (nextPage >= 0) {
            this.setState({pageIndex: nextPage}, () => {
                this.loadAccounts()
            })
        }
    }

    nextPage() {
        const nextPage = this.state.pageIndex + 1;
        if (nextPage < this.state.totalPages) {
            this.setState({pageIndex: nextPage}, () => {
                this.loadAccounts()
            });
        }
    }

    close() {
        this.props.onClose()
    }

    async changeUser(account, selection) {
        if (selection) {
            await this.props.client.put(`/accounts/${account.id}/holder`, {
                userId: selection.userId
            })
        } else {
            await this.props.client.delete(`/accounts/${account.id}/holder`)
        }
        this.loadAccounts();
        this.loadUnassignedUsers()
    }

    async unassignLicense(accountId) {
        await this.props.client.delete(`/accounts/${accountId}/holder`)
        this.loadAccounts();
        this.loadUnassignedUsers()
    }

    customFilter(candidate, input) {
        return candidate.data.name.includes(input) || candidate.data.email.includes(input)
    }

    componentDidMount() {
        this.loadAccounts();
        this.loadUnassignedUsers()
    }

    render() {
        return (
            <Modal
                isOpen={true}
                style={customModalStyles}>
                <fieldset>
                    <legend>Edit Accounts</legend>
                    {!this.state.loading &&
                    this.state.accounts.map((account) => {
                        let unassignedUsers = [].concat(this.state.unassignedUsers);
                        if (account.holder) {
                            unassignedUsers.push(account.holder)
                        }
                        return (<Account account={account}
                                         unassignedUsers={unassignedUsers}
                                         changeUser={this.changeUser}
                                         unassignLicense={this.unassignLicense}
                                         deleteAccount={this.deleteAccount}
                                         key={account.id}
                                         customFilter={this.customFilter}
                                         copy={this.copy}/>);
                    })}
                    <div>
                        <input type="number" min={1} max={100} value={this.state.numberOfAccountsToAdd}
                               onChange={(e) => this.setState({numberOfAccountsToAdd: e.currentTarget.value})}
                               required={true}/>
                        <button onClick={this.addAccountsAndUpdate}>Add Account</button>
                        <button onClick={this.close}>Close</button>
                        <button onClick={this.firstPage}>First Page</button>
                        <button onClick={this.prevPage} disabled={this.state.pageIndex === 0}>Previous Page</button>
                        <button onClick={this.nextPage}
                                disabled={this.state.pageIndex >= this.state.totalPages - 1}>Next Page
                        </button>
                        <button onClick={this.lastPage}>Last Page</button>
                    </div>
                </fieldset>
            </Modal>
        );
    }
}

function Account(props) {
    return (
        <div key={props.account.id}>
            <label>-</label>
            <Select
                value={props.account.holder}
                options={props.unassignedUsers}
                isSearchable={true}
                getOptionLabel={(option) =>
                    option.verified ? `${option.name} (${option.email})` : <UnverifiedUser user={option}/>
                }
                getOptionValue={option => option.userId}
                filterOption={props.customFilter}
                onChange={(selection) => props.changeUser(props.account, selection)}/>
            <button onClick={() => props.unassignLicense(props.account.id)}>
                Unassign
            </button>
            <button onClick={() => props.deleteAccount(props.account.id)}>
                Delete
            </button>
        </div>
    );
}

function UnverifiedUser(props) {
    return (
        <div>
            <span style={{color: "red"}}>{props.user.name} ({props.user.email})</span>
        </div>
    )
}

export {AccountsDialog}
