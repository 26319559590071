import React, {Component} from 'react';
import Modal from 'react-modal';
import ReactTable from 'react-table';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {BulkInvite} from "./bulk_invite";


const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '15'
    }
};

class Employees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            openBulkInvite: false,
            data: [],
            pages: -1,
            pageSize: 10,
            pageIndex: 0,
            sort: [],
        };

        this.loadUsers = this.loadUsers.bind(this);
        this.cancel = this.cancel.bind(this)
    }

    async loadUsers() {
        this.setState({loading: true});
        const id = this.props.customerId;
        if (id) {
            let sortBy = "";
            let sortDirection = "";
            if (this.state.sort.length > 0) {
                let sort = this.state.sort[0];
                sortBy = sort.id;
                if (sort.desc) {
                    sortDirection = "DESC";
                } else {
                    sortDirection = "ASC";
                }
            }
            const response = await this.props.client.get(`/customers/${id}/users`, {
                params: {
                    page: this.state.pageIndex,
                    size: this.state.pageSize,
                    sortDirection,
                    sortBy,
                }
            });
            this.setState({
                employees: response.data.content,
                pages: response.data.totalPages,
                loading: false,
            })
        }
    }

    cancel() {
        this.props.onClose()
    }

    render() {
        return (
            <div>
                {this.state.openBulkInvite &&
                <BulkInvite customerId={this.props.customerId}
                            bulkUpdateClient={this.props.bulkUpdateClient}
                            onClose={() => {
                                this.setState({openBulkInvite: false});
                                this.loadUsers();
                            }}/>}
                <Modal
                    isOpen={this.state.open}
                    style={customModalStyles}>
                    <fieldset>
                        <legend>Employees</legend>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name",
                                    sortable: false,
                                },
                                {
                                    Header: "Email",
                                    accessor: "email",
                                    sortable: false,
                                },
                                {
                                    Header: "Assigned",
                                    accessor: "assigned",
                                    sortable: false,
                                    Cell: props =>
                                        props.value ? "assigned" : "not assigned"
                                },
                                {
                                    Header: "Verified",
                                    accessor: "activationLink",
                                    sortable: false,
                                    Cell: props =>
                                        props.value === "" ? <p>Verified</p> :
                                            <CopyToClipboard text={props.value}>
                                                <button>Copy activation link</button>
                                            </CopyToClipboard>,
                                }
                            ]}
                            data={this.state.employees}
                            pageSizeOptions={[5, 10, 20]}
                            defaultPageSize={10}
                            pages={this.state.pages}
                            manual
                            multiSort={false}
                            onFetchData={(state, instance) => {
                                this.setState({
                                    pageSize: state.pageSize,
                                    pageIndex: state.page,
                                    sort: state.sorted
                                }, () => this.loadUsers());
                            }}
                            getTdProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                };
                            }}/>
                    </fieldset>
                    <InviteDialog customerId={this.props.customerId}
                                  client={this.props.client}
                                  openBulkInvite={() => {
                                      this.setState({openBulkInvite: true});
                                  }}
                                  updateUsers={() => {
                                      this.loadUsers()
                                  }}/>
                    <button onClick={this.cancel}>Cancel</button>
                </Modal>
            </div>
        )
    }
}

class InviteDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ""
        };

        this.changeEmail = this.changeEmail.bind(this);
        this.invite = this.invite.bind(this)
    }

    changeEmail(e) {
        const email = e.target.value;
        this.setState({
            email
        })
    }

    async invite() {
        await this.props.client.post(`/customers/${this.props.customerId}/employees`,
            {
                "email": this.state.email
            });
        await this.props.updateUsers()
    }

    openBulkInvite() {
        this.props.openBulkInvite()
    }

    render() {
        return (
            <fieldset>
                <legend>Invite</legend>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input id="email" value={this.state.email} onChange={this.changeEmail} type="text"/>
                    <button onClick={this.invite}>Invite</button>
                    <button style={{float: "right"}} onClick={this.props.openBulkInvite}>Bulk role set</button>
                </div>
            </fieldset>
        )
    }
}

export {Employees}
