import React, {Component} from "react";
import MultiSelect from "@kenshooui/react-multi-select";
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async'
import "@kenshooui/react-multi-select/dist/style.css"

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '15'
    }
};


class MoveProjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectsPage: 0,
            projectsPageSize: 20,
            projectsSearchTerm: "",
            organisationsPage: 0,
            organisationsPageSize: 20,
            organisationsSearchTerm: "",
            organisationsLoaded: false,
        };

        this.loadProjects = this.loadProjects.bind(this);
        this.loadOrganisations = this.loadOrganisations.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
    }

    cancel() {
        this.props.onClose()
    }

    async submit() {
        const transferProjectsDTO = {
            "organisationId": this.state.selectedOrganisation.organisationId,
            "projectIds": this.state.selectedProjects.map(project => project.id),
        };
        await this.props.projectsClient.patch(`/support/transferProjects`, transferProjectsDTO);
        this.loadProjects();
        this.setState({selectedProjects: [], organisationSearchTerm: "", selectedOrganisation: {}})
    }


    componentDidMount() {
        this.loadProjects();
        this.loadOrganisations()
    }

    async loadProjects(search) {
        const params = {
            page: this.state.projectsPage,
            size: this.state.projectsPageSize,
            search: search
        };
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.projectsClient.get(`/support/${id}/projects`, {
                params
            });
            var data = response.data;
            //workaround since multiselect expects a label value to work
            var i;
            for (i = 0; i < data.length; i++) {
                data[i].label = data[i]['name'];
            }
            this.setState({projects: data})
        }
    }

    async loadOrganisations(search) {
        const params = {
            page: this.state.organisationsPage,
            size: this.state.organisationsPageSize,
            search: search
        };
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.client.get('/customers', {
                params
            });
            return response.data.content;
        }
    }

    render() {
        return (
            <Modal
                isOpen={true}
                style={customModalStyles}>
                <div>
                    Select projects to move and an organisation to move them to
                    <AsyncSelect
                        cacheOptions
                        // defaultOptions
                        loadOptions={this.loadOrganisations}
                        getOptionLabel={(option) => option["name"]}
                        getOptionValue={(option) => option["label"]}
                        value={this.state.selectedOrganisation}
                        onChange={selectedOrganisation => this.setState({selectedOrganisation})}
                    />
                    <MultiSelect
                        cacheOptions
                        items={this.state.projects}
                        onChange={selectedProjects => this.setState({selectedProjects})}
                        selectedItems={this.state.selectedProjects}
                        searchValueChanged={this.loadProjects}
                    />
                    <input id="cancel-button" type="button" value="Close" onClick={this.cancel}/>
                    <input id="submit-button" type="button" value="Move" onClick={this.submit}
                           disabled={!this.state.selectedOrganisation}/>
                </div>
            </Modal>
        );
    }
}

export {MoveProjects}
