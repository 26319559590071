import React, {Component} from 'react';
import Modal from 'react-modal';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '15px'
    }
};

const marginStyle = {
    margin: '10px',
};

class BulkInvite extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
    }

    handleSubmit(event) {
        event.preventDefault();
        let file = this.fileInput.current.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", file);
        this.props.bulkUpdateClient.patch(`/${this.props.customerId}`, bodyFormData);
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                isOpen={true}
                style={customModalStyles}>
                <form id="uploadForm" onSubmit={this.handleSubmit}>
                    <label htmlFor="update_file" style={marginStyle}>
                        Upload csv file containing employee data
                    </label>
                    <br/>
                    <input type="file"
                           id="update_file"
                           name="update_file"
                           accept="text/csv"
                           required={true}
                           ref={this.fileInput}
                           style={marginStyle}/>
                    <br/>
                    <div>
                        <button type="submit" style={marginStyle}>Update</button>
                        <button style={{float: "right"}} onClick={() => this.props.onClose()}>Cancel</button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export {BulkInvite}
