import React, {Component} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import {AccountsDialog} from './accounts.js';
import {Employees} from './employees.js'
import {CustomerDialog} from './customer_dialog.js'
import {Credentials} from "./credentials";
import Modal from "react-modal";


const DATE_COLUMN_WIDTH = 125;
const DATE_DISPLAY_FORMAT = "DD.MM.YYYY";

Modal.setAppElement('#root');

class CustomersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            columns: [
                {
                    Header: "Company",
                    columns: [
                        {
                            Header: "Invited",
                            id: "invited",
                            accessor: d => d.invited ? (d.invitedBy == null ? "Yes, unknown" : d.invitedBy.username) : "No",
                            sortable: false,
                            style: {'wordBreak': 'break-all', 'whiteSpace': 'pre-line'},
                        },
                        {
                            Header: "Name",
                            accessor: "name",
                            style: {'whiteSpace': 'pre-line'}
                        },
                        {
                            Header: "Created",
                            width: DATE_COLUMN_WIDTH,
                            id: "created",
                            accessor: d => d.created ? moment(d.created, "MM-DD-YYYY").format(DATE_DISPLAY_FORMAT) : ''
                        },
                        {
                            Header: "Billing Mail",
                            accessor: "billingMail",
                            Cell: props => <a href={`mailto:${props.value}`}>{props.value}</a>,
                            width: 275,
                        }
                    ],
                },
                {
                    Header: "Owner",
                    accessor: "owner.name",
                    width: 225,
                    Cell: props =>
                        <p>{`${props.value.firstName} ${props.value.lastName}`}</p>,
                },
                {
                    Header: "Subscription",
                    columns: [
                        {
                            Header: "Type",
                            accessor: "subscription.type"
                        },
                        {
                            Header: "Plan",
                            accessor: "subscription.paymentPlan"
                        },
                        {
                            Header: "Since",
                            id: "subscription.since",
                            width: DATE_COLUMN_WIDTH,
                            accessor: d => d.subscription.since ? moment(d.subscription.since).format(DATE_DISPLAY_FORMAT) : ''
                        },
                        {
                            Header: "Until",
                            width: DATE_COLUMN_WIDTH,
                            id: "subscription.until",
                            accessor: d => d.subscription.until ? moment(d.subscription.until).format(DATE_DISPLAY_FORMAT) : ''
                        },
                    ]
                },
                {
                    Header: "Accounts",
                    columns: [
                        {
                            Header: "Available",
                            accessor: "subscription.availableAccounts",
                            sortable: false
                        },
                        {
                            Header: "Used",
                            accessor: "subscription.usedAccounts",
                            sortable: false
                        }
                    ]
                },
                {
                    id: "buttons",
                    accessor: d => d,
                    Cell: props =>
                        <div>
                            <div>
                                <button onClick={() => this.openEdit(props.value.organisationId)}>Edit</button>
                            </div>
                            <div>
                                <button onClick={() => this.openAccounts(props.value.organisationId)}>Accounts</button>
                            </div>
                            <div>
                                <button onClick={() => this.openEmployees(props.value.organisationId)}>Employees
                                </button>
                            </div>
                            <div>
                                <button onClick={() => this.openCredentials(props.value.organisationId,
                                    props.value.hasClientCredentials)}>
                                    Credentials
                                </button>
                            </div>
                        </div>,
                    sortable: false
                }
            ],
            pageSize: 25,
            sort: {
                id: "created",
                desc: true
            },
            searchTerm: ""
        };

        this.changePageSize = this.changePageSize.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.search = this.search.bind(this);
    }

    openEdit(id) {
        this.setState({
            editId: id,
            edit: true
        })
    }

    openAccounts(id) {
        this.setState({
            accountsId: id,
            accounts: true
        })
    }

    openEmployees(id) {
        this.setState({
            employeesId: id,
            employees: true
        })
    }

    openCredentials(id, hasCredentials) {
        if (hasCredentials) {
            this.setState({
                customerId: id,
                credentialsWarning: true
            })
        } else {
            this.setState({
                customerId: id,
                credentials: true
            })
        }
    }

    componentDidMount() {
        this.doLoadPageRequest(0, this.state.pageSize, null)
    }

    changePageSize(pageSize, pageIndex) {
        this.setState({
            pageSize: pageSize
        });
        this.doLoadPageRequest(pageIndex, pageSize, null)
    }

    changePage(page) {
        this.setState({
            page
        });
        this.doLoadPageRequest(page, this.state.pageSize, null)
    }

    changeSort(sorted) {
        const sort = sorted.pop();
        if (sort) {
            this.setState({
                sort
            });
            this.doLoadPageRequest(this.state.page, this.state.pageSize, sort)
        }
    }

    search(searchTerm) {
        this.setState({
            searchTerm: searchTerm
        });
        this.doLoadPageRequest(this.state.page, this.state.pageSize, null, searchTerm)
    }

    async doLoadPageRequest(page, pageSize, sorted, searchTerm) {
        let sortBy = this.state.sort.id;
        let sortDirection = this.state.sort.desc ? "DESC" : "ASC";
        let searchParam = searchTerm != null ? searchTerm : this.state.searchTerm;
        if (sorted) {
            sortBy = sorted.id;
            sortDirection = sorted.desc ? "DESC" : "ASC"
        }
        const params = {
            page: page,
            size: pageSize,
            sortBy: sortBy,
            sortDirection: sortDirection,
            search: searchParam,
        };
        const response = await this.props.client.get('/customers', {
            params
        });
        this.setState({
            data: response.data.content,
            page: page,
            pages: response.data.totalPages,
            loading: false,
            sort: {
                id: sortBy,
                desc: sortDirection === "DESC"
            }
        })
    }

    render() {
        return (
            <div className="root">
                <style>{"td{border:100px solid red;}"}</style>
                {this.state.edit &&
                <CustomerDialog customerId={this.state.editId} client={this.props.client}
                                projectsClient={this.props.projectsClient} open={this.state.edit} onClose={() => {
                    this.setState({edit: false});
                    this.doLoadPageRequest(this.state.page, this.state.pageSize, null)
                }}
                />}
                {this.state.accounts &&
                <AccountsDialog customerId={this.state.accountsId} client={this.props.client} open={this.state.accounts}
                                onClose={() => {
                                    this.setState({accounts: false});
                                    this.doLoadPageRequest(this.state.page, this.state.pageSize, null)
                                }}
                />}
                {this.state.employees &&
                <Employees customerId={this.state.employeesId} client={this.props.client} open={this.state.employees}
                           bulkUpdateClient={this.props.bulkUpdateClient}
                           onClose={() => {
                               this.setState({employees: false});
                               this.doLoadPageRequest(this.state.page, this.state.pageSize, null)
                           }}/>}
                {this.state.credentialsWarning &&
                <CredentialsWarning onCancel={() => {
                    this.setState({
                        credentialsWarning: false
                    });
                }} onAccept={() => {
                    this.setState({
                        credentialsWarning: false,
                        credentials: true
                    })
                }}/>}
                {this.state.credentials &&
                <Credentials customerId={this.state.customerId}
                             credentialsClient={this.props.credentialsClient}
                             onClose={() => {
                                 this.setState({credentials: false});
                                 this.doLoadPageRequest(this.state.page, this.state.pageSize, null)
                             }}/>}
                <fieldset>
                    <div>
                        <legend htmlFor="#search">Search:</legend>
                        <SearchForm searchCallback={this.search}/>
                    </div>
                    <div>
                        <a
                            href={`${this.props.csvUrl}&search=${this.state.searchTerm}&sortBy=${this.state.sort.id}&sortDirection=${this.state.sort.desc ? "DESC" : "ASC"}`}
                            download="docu-tools-customers.csv">
                            Download CSV
                        </a>
                    </div>
                </fieldset>
                <ReactTable manual
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            pageSize={this.state.pageSize}
                            pageSizeOptions={[5, 10, 20, 25, 50, 100, 200]}
                            defaultPageSize={25}
                            sorted={[this.state.sort]}
                            loading={this.state.loading}
                            onPageSizeChange={this.changePageSize}
                            onPageChange={this.changePage}
                            onSortedChange={this.changeSort}
                            getTdProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                };
                            }}/>
            </div>
        )
    }

}

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ""
        };

        this.changeSearch = this.changeSearch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.search = this.search.bind(this)
    }

    changeSearch(event) {
        this.setState({
            search: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.searchCallback(this.state.search)
    }

    search(event) {
        this.props.searchCallback(this.state.search)
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <input id="search" type="text" value={this.state.search} onChange={this.changeSearch}/>
                </form>
                <button onClick={this.search}>Search</button>
            </div>
        )
    }
}

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class CredentialsWarning extends Component {

    render() {
        return (
            <Modal
                isOpen={true}
                style={customModalStyles}>
                <div>
                    <h4>Are you sure you want to reset the credentials?
                        All currently dependent services will be stopped/fail until updated.</h4>
                    <input id="cancel-button" type="button" value="Cancel" onClick={this.props.onCancel}/>
                    <input id="accept-button" type="button" value="Accept" onClick={this.props.onAccept}/>
                </div>
            </Modal>
        )
    }
}

export {CustomersTable}
