import React, {Component} from 'react';
import Modal from "react-modal";
import {CopyToClipboard} from "react-copy-to-clipboard";

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Credentials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerId: "",
            customerSecret: "",
        };

        this.loadCredentials = this.loadCredentials.bind(this);
        this.close = this.close.bind(this)
    }


    componentDidMount() {
        this.loadCredentials()
    }

    async loadCredentials() {
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.credentialsClient.put(`/credentials`, {organisation: id});
            this.setState({
                clientId: response.data.clientId,
                clientSecret: response.data.clientSecret,
            })
        }
    }

    close() {
        this.props.onClose()
    }

    render() {
        return (
            <Modal
                isOpen={true}
                style={customModalStyles}>
                <div>
                    Client id: {this.state.clientId}
                    <br/>
                    Client Secret: {this.state.clientSecret}
                </div>
                <input id="cancel-button" type="button" value="Close" onClick={this.close}/>
                <CopyToClipboard text={this.state.clientSecret}>
                    <button>Copy secret</button>
                </CopyToClipboard>
            </Modal>
        )
    }
}

export {Credentials}
