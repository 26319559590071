import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class LoginDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            loginError: false
        };
        this.login = this.login.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setEmail(email) {
        this.setState({
            email
        })
    }

    setPassword(password) {
        this.setState({
            password
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.login()
    }

    async login() {
        this.setState({
            loginError: false
        });
        var params = new URLSearchParams();
        params.append('username', this.state.email);
        params.append('password', this.state.password);
        params.append('grant_type', 'password');
        params.append('grant_type', 'refresh');
        try {
            const response = await axios({
                method: 'post',
                url: `${this.props.host}/oauth/token`,
                headers: {
                    'Authorization': 'Basic dGVzdGVyOnNlY3JldA=='
                },
                data: params
            });
            const {access_token, expires_in} = response.data;
            this.props.onLogin(access_token, expires_in)
        } catch (e) {
            this.setState({
                loginError: true
            });

        }
    }

    render() {
        return (
            <Modal
                isOpen={this.state.open}
                style={customModalStyles}>
                <form onSubmit={this.handleSubmit}>
                    <fieldset>
                        <legend>Login</legend>
                        {
                            <div>
                                <p style={{color: 'lightGrey'}}>Press Enter or the Login Button to login</p>
                            </div>
                        }
                        {this.state.loginError &&
                        <div>
                            <p style={{color: 'red'}}>Wrong username or password!</p>
                        </div>
                        }
                        <div>
                            <label htmlFor="email">Email:</label>
                            <input id="email" type="text" value={this.state.email || ''}
                                   onChange={(e) => this.setEmail(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="password">Password:</label>
                            <input id="password" type="password" value={this.state.password || ''}
                                   onChange={(e) => this.setPassword(e.target.value)}/>
                        </div>
                        <div>
                            <button onClick={this.login}>Login</button>
                        </div>
                    </fieldset>
                </form>
            </Modal>
        );
    }

}

export {LoginDialog}
