import React, {Component} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import countryList from './countries.json'
import reactCountryList from './react-select-countries'
import DatePicker from 'react-datepicker';
import {format} from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css';
import {MoveProjects} from './move_projects.js'

const DATE_DISPLAY_FORMAT = "dd.MM.yyyy";
const DATE_JSON_FORMAT = "yyyy-MM-dd";

const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '15'
    }
};

class CustomerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: {
                name: "",
                cc: "at",
                billingMail: "",
                subscription: {
                    type: "Test",
                    paymentPlan: "Annually",
                    paymentType: "Other",
                    until: "",
                    postalBills: false,
                    moveProjects: false,
                },
                owner: {
                    name: {
                        firstName: "",
                        lastName: ""
                    },
                    email: "",
                }
            },
            update: {
                subscription: {}
            },
            employees: []
        };
        this.loadCustomer = this.loadCustomer.bind(this);
        this.loadEmployees = this.loadEmployees.bind(this);
        this.loadStats = this.loadStats.bind(this);
        this.setCountry = this.setCountry.bind(this);
        this.setSubscriptionType = this.setSubscriptionType.bind(this);
        this.setPaymentPlan = this.setPaymentPlan.bind(this);
        this.setPaymentType = this.setPaymentType.bind(this);
        this.setUntil = this.setUntil.bind(this);
        this.setOwner = this.setOwner.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openMoveProjects = this.openMoveProjects.bind(this)
    }

    setName(name) {
        this.setState({
            customer: {
                ...this.state.customer,
                name
            },
            update: {
                ...this.state.update,
                name
            }
        })
    }

    setCountry(e) {
        const cc = e.value;
        this.setState({
            customer: {
                ...this.state.customer,
                cc
            },
            update: {
                ...this.state.update,
                cc
            }
        })
    }

    setBillingMail(billingMail) {
        this.setState({
            customer: {
                ...this.state.customer,
                billingMail
            },
            update: {
                ...this.state.update,
                billingMail
            }
        })
    }

    setSubscriptionType(newSubscriptionType) {
        let customer = this.state.customer;
        customer.subscription.type = newSubscriptionType.value;
        let update = this.state.update;
        update.subscription.type = customer.subscription.type;
        this.setState({
            customer,
            update
        })
    }

    setPaymentPlan(paymentPlan) {
        let customer = this.state.customer;
        customer.subscription.paymentPlan = paymentPlan.value;
        let update = this.state.update;
        update.subscription.paymentPlan = customer.subscription.paymentPlan;
        this.setState({
            customer,
            update
        })
    }

    setPaymentType(paymentType) {
        let customer = this.state.customer;
        customer.subscription.paymentType = paymentType.value;
        let update = this.state.update;
        update.subscription.paymentType = customer.subscription.paymentType;
        this.setState({
            customer,
            update
        })
    }

    setPostalBills(postalBills) {
        let customer = this.state.customer;
        customer.subscription.postalBills = postalBills;
        let update = this.state.update;
        update.subscription.postalBills = customer.subscription.postalBills;
        this.setState({
            customer,
            update
        })
    }

    setUntil(until) {
        let customer = this.state.customer;
        let update = this.state.update;
        if (until) {
            customer.subscription.until = format(until, DATE_JSON_FORMAT);
            update.subscription.until = customer.subscription.until;
            update.subscription.removeUntil = false
        } else {
            customer.subscription.until = null;
            update.subscription.removeUntil = true
        }
        this.setState({
            customer,
            update
        })
    }

    setOwner(selection) {
        let customer = this.state.customer;
        let update = this.state.update;
        customer.owner = selection;
        update.owner = selection;
        this.setState({
            customer,
            update
        })
    }

    openMoveProjects() {
        this.setState({
            moveProjects: true
        })
    }

    async save() {
        const id = this.props.customerId;
        if (id) {
            await this.props.client.patch(`/customers/${id}`, this.state.update)
        } else {
            console.log("Cannot update: No ID specified for CustomerDialog!")
        }
        this.props.onClose()
    }

    cancel() {
        this.props.onClose()
    }

    componentDidMount() {
        this.loadCustomer();
        this.loadEmployees();
        this.loadStats();
    }

    async loadCustomer() {
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.client.get(`/customers/${id}`);
            this.setState({customer: response.data})
        } else {
            console.log("No ID specified for CustomerDialog!")
        }
    }

    async loadEmployees() {
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.client.get(`/customers/${id}/employees`);
            this.setState({employees: response.data})
        } else {
            console.log("No ID specified for CustomerDialog!")
        }
    }

    async loadStats() {
        const id = this.props.customerId;
        if (id) {
            const response = await this.props.projectsClient.get(`/stats/${id}`);
            this.setState({
                noActiveProjects: response.data.noActiveProjects,
                noTotalProjects: response.data.noTotalProjects,
                noActivePlans: response.data.noActivePlans,
                noTotalPlans: response.data.noTotalPlans
            })
        } else {
            console.log("No ID specified for Projects!")
        }
    }

    render() {
        return (
            <div>
                <div>
                    {this.state.moveProjects &&
                    <MoveProjects customerId={this.props.customerId} client={this.props.client}
                                  projectsClient={this.props.projectsClient}
                                  onClose={() => {
                                      this.setState({moveProjects: false})
                                  }}
                    />}
                </div>
                <Modal
                    isOpen={true}
                    style={customModalStyles}>
                    <fieldset>
                        <legend>Edit: {this.state.customer.name}</legend>
                        <div>
                            <label htmlFor="name">Name:</label>
                            <input id="name" type="text" onChange={(e) => this.setName(e.target.value)}
                                   value={this.state.customer.name}/>
                        </div>
                        <div>
                            <label htmlFor="country">Country:</label>
                            <Select id="country"
                                    value={{
                                        "value": this.state.customer.cc,
                                        "label": countryList[this.state.customer.cc.toUpperCase()]
                                    }}
                                    options={reactCountryList}/>
                        </div>
                        <div>
                            <label htmlFor="billingMail">Billing Mail:</label>
                            <input id="billingMail" type="text" onChange={(e) => this.setBillingMail(e.target.value)}
                                   value={this.state.customer.billingMail}/>
                        </div>
                        <div>
                            <label htmlFor="owner">Owner:</label>
                            <Select id="owner"
                                    value={this.state.customer.owner}
                                    options={this.state.employees}
                                    getOptionValue={option => option.userId}
                                    getOptionLabel={option => `${option.name.firstName} ${option.name.lastName} (${option.email})`}
                                    onChange={this.setOwner}/>
                        </div>
                        <div>
                            <label htmlFor="subscriptionType">Subscription:</label>
                            <Select id="subscriptionType"
                                    value={{
                                        value: this.state.customer.subscription.type,
                                        label: this.state.customer.subscription.type
                                    }}
                                    options={[
                                        {value: 'Test', label: 'Test'},
                                        {value: 'Pocket', label: 'Pocket'},
                                        {value: 'Combo', label: 'Combo'},
                                        {value: 'Multi', label: 'Multi'},
                                        {value: 'Master', label: 'Master'},
                                        {value: 'Canceled', label: 'Canceled'}
                                    ]}
                                    onChange={this.setSubscriptionType}/>
                        </div>
                        <div>
                            <label htmlFor="paymentPlan">Plan:</label>
                            <Select id="paymentPlan"
                                    value={{
                                        value: this.state.customer.subscription.paymentPlan,
                                        label: this.state.customer.subscription.paymentPlan
                                    }}
                                    options={[
                                        {value: 'Annually', label: 'Annually'},
                                        {value: 'Monthly', label: 'Monthly'},
                                        {value: 'OneTime', label: 'One Time'}
                                    ]}
                                    onChange={this.setPaymentPlan}/>
                        </div>
                        <div>
                            <label htmlFor="paymentType">Method:</label>
                            <Select id="paymentType"
                                    value={{
                                        value: this.state.customer.subscription.paymentType,
                                        label: this.state.customer.subscription.paymentType
                                    }}
                                    options={[
                                        {value: 'DirectDebit', label: 'Direct Debit'},
                                        {value: 'CreditCard', label: 'Credit Card'},
                                        {value: 'Other', label: 'Other'}
                                    ]}
                                    onChange={this.setPaymentType}/>
                        </div>
                        <div>
                            <label htmlFor="postalBills">Postal Bills:</label>
                            <input id="postalBills"
                                   type="checkbox"
                                   onChange={(e) => this.setPostalBills(e.target.checked)}
                                   checked={this.state.customer.subscription.postalBills}/>
                        </div>
                        <div>
                            <label htmlFor="until">Until:</label>
                            <DatePicker
                                selected={this.state.customer.subscription.until ? new Date(this.state.customer.subscription.until) : new Date()}
                                onChange={this.setUntil}
                                dateFormat={DATE_DISPLAY_FORMAT}/>
                        </div>
                        Projects: {this.state.noActiveProjects} / {this.state.noTotalProjects}
                        <br/>
                        Plans: {this.state.noActivePlans} / {this.state.noTotalPlans}
                        <br/>
                        Reseller: {this.state.customer.reseller}
                        <br/>
                        Has Client Credentials (API Key): {this.state.customer.hasClientCredentials ? "✓" : "x"}
                        <div>
                            <input id="move-projects-button" type="button" value="Move projects"
                                   onClick={this.openMoveProjects}/>
                            <input id="save-button" type="button" value="Save" onClick={this.save}/>
                            <input id="cancel-button" type="button" value="Cancel" onClick={this.cancel}/>
                        </div>
                    </fieldset>
                </Modal>
            </div>
        )
    }
}

export {CustomerDialog}
