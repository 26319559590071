import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import {CustomersTable} from './customers.js';
import axios from 'axios';
import {LoginDialog} from './login.js';
import moment from 'moment';

const host = process.env.REACT_APP_DOCU_TOOLS_API;
const login_host = process.env.REACT_APP_DOCU_TOOLS_LOGIN_API || host;
const projects_host = process.env.REACT_APP_DOCU_TOOLS_PROJECTS_API || host;
const bulk_update_host = process.env.REACT_APP_DOCU_TOOLS_BULK_UPDATE_API || host;


let token = sessionStorage.token;
if (token === 'clear') {
    token = null
}
if (token) {
    if (moment(Number(token.expires_at)).isAfter(moment())) {
        token = null
    } else {
        let params = new URLSearchParams();
        params.append('token', token);
        axios({
            method: 'post',
            url: `${host}/oauth/check_token`,
            headers: {
                'Authorization': 'Basic dGVzdGVyOnNlY3JldA=='
            },
            data: params
        }).catch((error) => {
            console.log("Token expired!");
            sessionStorage.token = 'clear'
        });
    }
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token
        };
        this.setToken = this.setToken.bind(this)
    }

    buildClient() {
        let client = axios.create({
            baseURL: `${host}/api/v2`,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        });
        client.interceptors.request.use(function (config) {
            //Hack to be able to use api versioning withouth refactoring the project
            if (config.url.startsWith("/v3")) {
                config.url = config.url.substring(3);
                config.baseURL = config.baseURL.replace("v2", "v3");
            }
            return config;
        });
        return client
    }

    buildProjectsClient() {
        return axios.create({
            baseURL: `${projects_host}/projects-api/v3`,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        })
    }

    buildCredentialsClient() {
        return axios.create({
            baseURL: `${login_host}/client-credentials-api/v1`,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        })
    }

    buildBulkUpdateClient() {
        return axios.create({
            baseURL: `${bulk_update_host}/v1`,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        })
    }

    setToken(token, expiresIn) {
        this.setState({
            token
        });
        sessionStorage.token = token;
        sessionStorage.expires = moment().add(expiresIn, 's').format('x')
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <a href="%PUBLIC_URL%">
                        <img src={logo} className="App-logo" alt="logo"/>
                    </a>
                    <h1 className="App-title">Maestro</h1>
                </header>
                <p className="App-intro">
                    Web Application for Managing Customers, their Users and Subscriptions.
                </p>
                {this.state.token &&
                <CustomersTable client={this.buildClient()}
                                projectsClient={this.buildProjectsClient()}
                                credentialsClient={this.buildCredentialsClient()}
                                bulkUpdateClient={this.buildBulkUpdateClient()}
                                csvUrl={`${host}/api/v2/customers/all/csv?access_token=${this.state.token}`}/>}
                {!this.state.token &&
                <LoginDialog open={true} host={login_host} onLogin={this.setToken}/>}
            </div>
        );
    }
}

export default App;
